.movimentiFilters {
	border: 1px solid #ced4da;
	padding: 10px 10px 10px 20px;
	background-color: #f5faff;
	/*width: 1550px;*/
	display: block;
	display: flex;
	flex-wrap: wrap;
	column-gap: 2.3em;
}

.refuel_date {
	height: 38px;
	background-color: white;
	border: 1px solid #ccc;
	border-radius: 4px;
}
