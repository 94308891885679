.deviceContainer {
	font-size: 12px;
	border: 1px solid grey;
	border-radius: 5px;
	padding-top: 5px;
	margin-right: 5px;
	margin-left: 5px;
	background-color: rgb(86, 86, 93);
	color: white;
}

.targa {
	position: relative;
	display: inline-block;
	font-size: 22px;
	white-space: nowrap;
	font-weight: 500;
	border: 1px solid #000;
	box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.2);
	border-radius: 4px;
	padding: 0 16px 0px 16px;
	margin-bottom: 2px;
	background-color: white;
	color: black;
}

.targa:before,
.targa:after {
	content: '';
	position: absolute;
	right: 0;
	top: 0;
	width: 11px;
	height: 100%;
	background-color: #0055bb;
}

.targa:before {
	content: 'I';
	font-size: 12px;
	color: #fff;
	left: 0;
	right: auto;
	text-align: center;
	padding-top: 15px;
}

.in_scadenza {
	color: orange;
}

.scaduta {
	color: red;
	padding: 2px 2px 2px 2px;
	background-color: white;
	border-radius: 3px;
	margin-left: 3px;
}

.nd {
	color: #915400;
	padding: 2px 3px 2px 2px;
	background-color: white;
	border-radius: 3px;
	margin-left: 3px;
}

.regolare {
	color: green;
	padding: 2px 2px 2px 2px;
	background-color: white;
	border-radius: 3px;
	margin-left: 3px;
}
