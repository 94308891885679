.NavigationItem {
	margin: 10px 0px;
	box-sizing: border-box;
	display: block;
	width: 100%;
}

.NavigationItem a {
	color: #ccc;
	text-decoration: none;
	width: 100%;
	box-sizing: border-box;
	display: block;
}

.NavigationItem a:hover,
.NavigationItem a:active,
.NavigationItem a.active {
	color: #40a4c8;
}

@media (min-width: 500px) {
	.NavigationItem {
		margin: 0 1px;
		display: flex;
		height: 100%;
		width: auto;
		align-items: center;
	}

	.NavigationItem a {
	    display: inline-block;
        white-space: nowrap;
        color: white;
        height: 100%;
        padding: 0 12px;
        line-height: 56px;
	}

	.NavigationItem a:hover,
	.NavigationItem a:active,
	.NavigationItem a.active {
		background-color: #ccc;
		border-bottom: 4px solid #40a4c8;
		color: black;
	}

	.NavigationItem a svg {
	    vertical-align: baseline;
	    transform: translateY(.2em);
	}
}
