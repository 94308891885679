.unselectable {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
	font-size: 14px !important;
	padding: 0.275rem 0.25rem 0.275rem 0.3rem !important;
	height: calc(1em + 0.75rem + 2px) !important;
	line-height: 1.4 !important;
}

.filtroSv_cardHeader {
	padding: 0.25rem 0.5rem !important;
}

.input-group-text {
	padding-top: 0.175rem !important;
	padding-bottom: 0.2rem !important;
}

.checkbox {
	padding-top: 0.2rem !important;
	padding-bottom: 0.2rem !important;
	padding-left: 0.4rem !important;
	padding-right: 0.4rem !important;
}
