.movimenti .cella {
	color: black;
	cursor: pointer;
}

.movimenti .cella:hover {
	background-color: white !important;
	color: black;
}

.movimenti .festivo {
	background-color: yellow;
	border-top: 3px solid #333;
}

.movimenti .weekend {
	background-color: rgb(255, 112, 112);
	border-top: 3px solid #333;
}

.movimenti table {
	white-space: nowrap;
}

.movimenti table thead tr th {
	border-bottom: 3px solid #333;
	border-top: 3px solid #333;
}

.movimenti table thead tr th:first-of-type {
	border-top: none;
	border-bottom: none;
	border-right: 3px solid #333;
}

.movimenti table thead tr th:last-of-type {
	border-right: 3px solid #333;
}

.movimenti table tr th {
	text-align: center;
	padding: 6px 12px;
	font-size: 18px;
}

.movimenti table tr td {
	text-align: center;
	padding: 6px 12px;
	/*font-size: 16px;*/
	font-size: 15px;
}

.movimenti table tr td:first-child {
	text-align: left;
}

.movimenti table tr:last-of-type td {
	border-bottom: 3px solid #333;
}
.movimenti table tr:last-of-type td:first-of-type {
	border-bottom: none;
}

.movimenti tr td:hover {
	background: #ededed;
}
.movimenti table tr td:first-of-type {
	border-right: 3px solid #333;
}

.movimenti table tr td:last-of-type {
	border-right: 3px solid #333;
}

.movimentiFilters {
	border: 1px solid #ced4da;
	padding: 10px 10px 10px 20px;
	margin-bottom: 5px;
	background-color: #f5faff;
	width: 1350px;
	display: block;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	flex-wrap: wrap;
	column-gap: 4em;
}

.movimentiFiltersCC {
	border: 1px solid #ced4da;
	padding: 10px 10px 10px 20px;
	margin-bottom: 5px;
	background-color: #f5faff;
	width: 580px;
	display: block;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	flex-wrap: wrap;
	column-gap: 4em;
}

.selected {
	background-color: rgb(255, 165, 0);
}

.select_placeholder {
	font-size: 12px;
}

.fixTableHead {
	overflow-y: auto;
	height: 770px;
}

.fixTableHead thead th {
	position: sticky;
	top: 0;
	border-bottom: 3px solid #333;
}

.fixTableHead table {
	border-collapse: collapse;
	/*border-bottom: 3px solid #333;*/
}

.fixTableHead th,
.fixTableHead td {
	/*padding: 8px 15px;
	font-size: 14px;
	border: 2px solid #328d94;*/
	text-align: center;
	padding: 6px 12px;
}
.fixTableHead th {
	background: #abdd93;
}

.targa {
	position: relative;
	display: inline-block;
	font-size: 22px;
	white-space: nowrap;
	font-weight: 500;
	border: 1px solid #000;
	box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.2);
	border-radius: 4px;
	padding: 0 16px 0px 16px;
	margin-bottom: 2px;
	cursor: pointer;
}

.targa:before,
.targa:after {
	content: '';
	position: absolute;
	right: 0;
	top: 0;
	width: 11px;
	height: 100%;
	background-color: #0055bb;
}

.targa:before {
	content: 'I';
	font-size: 12px;
	color: #fff;
	left: 0;
	right: auto;
	text-align: center;
	padding-top: 15px;
}
