.Container {
	height: 200px;
	width: 100%;
	background-color: #fff;
	border-radius: 5px;
	border-color: grey;
}

.DatePiker {
	height: 35px;
	width: 245px;
	text-align: center;
}

.SelectHour {
	height: 34px;
}

.Buttons {
	font-size: xx-small;
	width: 20px;
	height: 16px;
}

.MarginBottom {
	margin-bottom: 1px;
}

.PaddingTop {
	padding-top: -1px;
}

.TimeButton {
	width: 35px;
	height: 29px;
}

.TimerLeft {
	float: left;
}

.TimerRight {
	float: right;
}

/*
	Summary risultati
*/

.statusbar {
	padding-bottom: 5px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

h2.mb-0 {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.markerTitle {
	font-size: 14px;
}

.header {
	padding-left: 6px !important;
	padding-right: 6px !important;
}

.card_body {
	padding: 0px !important;
}

table {
	margin-bottom: 0px !important;
}

.filtroStorico_cardHeader {
	padding: 00.25rem 0.5rem;
}
