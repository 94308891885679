.alerts-scadenze {
    position: fixed;
    top: 65px;
    right: 39px;
    max-width: 60vw;
    z-index: 999;
}

.modal-scadenze {
    p {
        margin-top: 1rem;

        &.in_scadenza {
            color: orange;
        }

        &.scaduta {
            color: red;
        }
    }
}