	.targa {
		position: relative;
		display: inline-block;
		font-size: 26px;
		white-space: nowrap;
		font-weight: 500;
		border: 1px solid #000;
		box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.2);
		border-radius: 4px;
		padding: 0 16px 0px 16px;
		/*margin-bottom: 12px;*/
	}

	.targa:before,
	.targa:after {
		content: '';
		position: absolute;
		right: 0;
		top: 0;
		width: 11px;
		height: 100%;
		background-color: #0055bb;
	}

	.targa:before {
		content: 'I';
		font-size: 12px;
		color: #fff;
		left: 0;
		right: auto;
		text-align: center;
		padding-top: 20px;
	}
/*
.targa {
	position: relative;
	display: inline-block;
	white-space: nowrap;
	font-weight: 500;
	border: 1px solid #000;
	box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.2);
	border-radius: 4px;
	padding: 0 16px 0px 16px;
	margin-bottom: 2px;
	background-color: white;
	color: black;
}

.targa:before,
.targa:after {
	content: '';
	position: absolute;
	right: 0;
	top: 0;
	width: 11px;
	height: 100%;
	background-color: #0055bb;
}

.targa:before {
	content: 'I';
	font-size: 12px;
	color: #fff;
	left: 0;
	right: auto;
	text-align: center;
	padding-top: 8px;
}
*/

.nd {
	color: #915400;
}

td {
	height: 14px;
}


.fixTableHead {
	overflow-y: auto;
	height: 450px;
}

.fixTableHead thead th {
	position: sticky;
	top: 0;
	border-bottom: 3px solid #333;
}

.fixTableHead table {
	border-collapse: collapse;
	/*border-bottom: 3px solid #333;*/
}

.fixTableHead th,
.fixTableHead td {
	/*padding: 8px 15px;
	font-size: 14px;
	border: 2px solid #328d94;*/
	text-align: center;
	padding: 6px 12px;
}
.fixTableHead th {
	background: #f5faff;
}
