main {
	width: 100%;
}

.targa {
	position: relative;
	display: inline-block;
	font-size: 26px;
	white-space: nowrap;
	font-weight: 500;
	border: 1px solid #000;
	box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.2);
	border-radius: 4px;
	padding: 0 16px 2px 16px;
	margin-bottom: 12px;
}

.targa:before,
.targa:after {
	content: '';
	position: absolute;
	right: 0;
	top: 0;
	width: 11px;
	height: 100%;
	background-color: #0055bb;
}

.targa:before {
	content: 'I';
	font-size: 12px;
	color: #fff;
	left: 0;
	right: auto;
	text-align: center;
	padding-top: 20px;
}

.garage {
	padding: 20px 40px;

	.alerts {
		position: fixed;
		top: 65px;
		right: 39px;
		max-width: 60vw;
		z-index: 999;
	}

	a[disabled],
	a[disabled]:hover,
	a[disabled]:visited {
		text-decoration: none;
		color: #849db7;
		cursor: not-allowed;
	}

	.button {
		color: #007bff;
		cursor: pointer;
	}

	.in_scadenza {
		color: orange;
	}

	.scaduta {
		color: red;
	}

	.nd {
		color: #915400;
	}

	.regolare {
		color: green;
	}

	.filters {
		margin-bottom: 20px;
		border: 1px solid #ced4da;
		padding: 12px;
		background-color: #f5faff;
		box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.1);
	}

	.histDiv {
		border: 1px solid #ced4da;
	}

	p {
		margin-bottom: 0;
	}

	.container {
		max-width: calc(100% - 80px);
		margin: 0 auto;
		padding: 10px 0;
	}

	.sect-lead {
		padding: 12px 0 8px 0;
	}

	.details .entry {
		padding: 12px 0;
		margin-bottom: 20px;
		border: 1px solid #ced4da;
		box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.1);
	}

	.id-mezzo {
	}

	.targa {
		position: relative;
		display: inline-block;
		font-size: 26px;
		white-space: nowrap;
		font-weight: 500;
		border: 1px solid #000;
		box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.2);
		border-radius: 4px;
		padding: 0 16px 0px 16px;
		margin-bottom: 12px;
	}

	.targa:before,
	.targa:after {
		content: '';
		position: absolute;
		right: 0;
		top: 0;
		width: 11px;
		height: 100%;
		background-color: #0055bb;
	}

	.targa:before {
		content: 'I';
		font-size: 12px;
		color: #fff;
		left: 0;
		right: auto;
		text-align: center;
		padding-top: 20px;
	}

	.total {
		font-size: 18px;
		margin-top: 12px;
	}

	.histograms {
		margin-bottom: 20px;

		p {
			text-align: center;
			padding-bottom: 4px;
		}

		.col {
			text-align: center;
		}
	}

	.vega-embed {
		display: inline-block;

		&.has-actions {
			padding-right: 0 !important;

			details summary {
				display: none;
			}
		}
	}
}

.modal-90w {
	max-width: 90% !important;
}
.modal-70w {
	max-width: 70% !important;
}
.modal-50w {
	max-width: 50% !important;
}

.details {
	.row {
		.col {
			padding-left: 25px;
			padding-right: 25px;
		}

		p {
			&.lead {
				border-bottom: 1px solid #e7e7e7;
				padding-bottom: 4px;
				margin-bottom: 8px;
			}
		}

		.val {
			float: right;
		}
	}
}

.select_placeholder {
	font-size: 12px;
}
