.container {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: stretch;
}

.map {
	width: 100vw;
	height: 100%;
}

.tabs {
	height: 200px;
}
