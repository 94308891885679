.filters {
	padding: 20px 20px 13px 20px;
	margin: 20px;
	background-color: #f5faff;
	border: 1px solid #ced4da;
}

.refuel_date {
	height: 38px;
	background-color: white;
	border: 1px solid #ccc;
	border-radius: 4px;
}

.targa {
	position: relative;
	display: inline-block;
	font-size: 26px;
	font-weight: 500;
	border: 1px solid #000;
	box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.2);
	border-radius: 4px;
	padding: 0 16px 2px 16px;
	margin-bottom: 12px;
}

.targa:before,
.targa:after {
	content: '';
	position: absolute;
	right: 0;
	top: 0;
	width: 11px;
	height: 100%;
	background-color: #0055bb;
}

.targa:before {
	content: 'I';
	font-size: 12px;
	color: #fff;
	left: 0;
	right: auto;
	text-align: center;
	padding-top: 20px;
}

.fuel_bar {
	display: inline-block;
	width: 30px;
	height: 106px;
	/*height: 122px;*/
	position: relative;
	border: 2px solid green;
	padding: 2px;
	box-sizing: border-box;
}

​.mask {
	position: absolute;
	width: calc(100% - 4px);
	overflow: hidden;
	transform: rotate(180deg);
	bottom: 0;
	left: 2px;
}
​.tacca {
	height: 8px;
	margin-top: 2px;
	background-color: green;
}
​.tacca:first-of-type {
	background-color: red;
}

.select_placeholder {
	font-size: 12px;
}

.RisultatiCounter {
	font-size: 16px;
	padding-top: 20px;
}

.RisultatiCounterValue {
	color: #999;
	font-weight: 700;
}
