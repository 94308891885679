.Auth {
	margin: 20px auto;
	width: 80%;
	text-align: center;
	box-shadow: 0 2px 3px #ccc;
	border: 1px solid #eee;
	padding: 10px;
	box-sizing: border-box;
}

@media (min-width: 600px) {
	.Auth {
		width: 500px;
	}
}
