.flexContainer {
	padding: 0;
	margin: 0;
	list-style: none;

	-ms-box-orient: horizontal;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -moz-flex;
	display: -webkit-flex;
	display: flex;
}

.row {
	-webkit-flex-direction: row;
	flex-direction: row;
}

.flexItem {
	width: 200px;
	margin: 5px;
}
