.promiscIcon {
	border: 1px solid #ccc;
	padding: 3px;
	border-radius: 5px;
}

.promiscIcon:hover {
	border: 2px solid blue;
	padding: 2px;
	border-radius: 5px;
	cursor: pointer;
}
