.flexContainer {
	padding: 0;
	margin-bottom: 5px;
	list-style: none;

	-ms-box-orient: horizontal;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -moz-flex;
	display: -webkit-flex;
	display: flex;
}

.flexContainer_button {
	padding: 0;
	margin-bottom: 7px;
	list-style: none;
}
.row {
	-webkit-flex-direction: row;
	flex-direction: row;
}

.flexItem {
	width: 220px;
	margin: 5px;
}


.flexItem_tp {
	width: 135px;
	margin: 5px;
}




.flexItem_intervento_tipo {
	width: 220px;
	margin: 5px;
}

.fornitore_fornitore_gomme {
	width: 180px;
	margin: 5px;
}


.text_inputs {
	border-color: hsl(0, 0%, 80%);
	border-radius: 1px;
	background-color: hsl(0, 0%, 100%);
	align-items: center;
	border-width: 1px;
	border-style: solid;
	cursor: default;
}

.valid {
	color: 'green';
	background-color: hsl(117, 65%, 83%, 0.84);
	border: 1px solid green;
}

.error {
	color: 'red';
	background-color: hsl(0, 91%, 79%);
	border: 1px solid red;
}

.warning {
	color: 'black';
	background-color: hsl(29, 88%, 84%);
	border: 1px solid orange;
}

.info {
	color: 'black';
	background-color: #c2e5ff;
	border: 1px solid #0055bb;
}

.dateIntervento {
	height: 38px;
	background-color: white;
	border: 2px solid #ccc;
	border-radius: 4px;
	margin-top: 5px;
}


	.targa {
		position: relative;
		display: inline-block;
		font-size: 26px;
		white-space: nowrap;
		font-weight: 500;
		border: 1px solid #000;
		box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.2);
		border-radius: 4px;
		padding: 0 16px 0px 16px;
		/*margin-bottom: 12px;*/
	}

	.targa:before,
	.targa:after {
		content: '';
		position: absolute;
		right: 0;
		top: 0;
		width: 11px;
		height: 100%;
		background-color: #0055bb;
	}

	.targa:before {
		content: 'I';
		font-size: 12px;
		color: #fff;
		left: 0;
		right: auto;
		text-align: center;
		padding-top: 20px;
	}


.spinner {
    animation: spin infinite 5s linear;

    /*You can increase or decrease the timer (5s) to 
     increase or decrease the speed of the spinner*/
  }

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
