/*
 Set colour by device activity
*/
.class1_active_device {
	filter: hue-rotate(0deg) !important; /*green*/
}

.class1_nonActive_device {
	filter: hue-rotate(80deg) !important; /*blue*/
}

.class1_unknown_device {
	filter: grayscale(100%) !important;
}

/*----------------------------------------
 Set colour by groups of CC
*/

.class2_group1 {
	filter: hue-rotate(0deg) !important;
}

.class2_group2 {
	filter: hue-rotate(30deg) !important;
}

.class2_group3 {
	filter: hue-rotate(60deg) !important;
}

.class2_group4 {
	filter: hue-rotate(90deg) !important;
}

.class2_group5 {
	filter: hue-rotate(120deg) !important;
}

.class2_group6 {
	filter: hue-rotate(150deg) !important;
}

.class2_group7 {
	filter: hue-rotate(180deg) !important;
}

.class2_group8 {
	filter: hue-rotate(210deg) !important;
}

.class2_group9 {
	filter: hue-rotate(240deg) !important;
}
.class2_group10 {
	filter: hue-rotate(270deg) !important;
}
