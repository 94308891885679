.statusbar {
	padding-bottom: 5px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

h2.mb-0 {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.markerTitle {
	font-size: 14px;
}

.gebIcons {
	float: right;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
}

.myIcon {
	padding-left: 10px;
}

.header {
	padding-left: 6px !important;
	padding-right: 6px !important;
}

.card_body {
	padding: 0px !important;
}

table {
	margin-bottom: 0px !important;
}
